import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination'; // Import Swiper pagination styles

import { EffectFade, Autoplay, Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

const SwiperComponent = ({ slidesData }) => {
  const navigate = useNavigate();
  const [imageHeights, setImageHeights] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const heights = slidesData.map((_, index) => {
        const element = document.getElementById(`slide-image-${index}`);
        return element ? element.offsetHeight : 650; // Default height if element not found
      });
      setImageHeights(heights);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    slidesData.forEach((_, index) => {
      const element = document.getElementById(`slide-image-${index}`);
      if (element) {
        resizeObserver.observe(element);
      }
    });

    handleResize(); // Set initial heights
    return () => resizeObserver.disconnect();
  }, [slidesData]);

  const handleButtonClick = (link) => {
    navigate(link);
  };

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      effect="fade"
      modules={[EffectFade, Autoplay, Pagination]}
      pagination={{
        clickable: true,
        renderBullet: (index, className) => (
          `<span class="${className} custom-pagination-bullet">
            <span class="loader"></span>
          </span>`
        ),
      }}
      className="mySwiper product-visual"
    >
      {slidesData.map((slide, index) => (
        <SwiperSlide key={index}>
          <div
            className="relative flex items-center justify-center bg-gray-800 text-white"
            style={{ height: `${imageHeights[index] || 650}px` }} // Dynamic height
          >
            <img
              id={`slide-image-${index}`}
              src={slide.image}
              alt={slide.title}
              loading="lazy" // Enable lazy loading
              className="absolute inset-0 w-dvw lg:h-auto h-[400px] object-cover lg:object-contain opacity-80 bg-fixed"
            />
            <div className="absolute lg:bg-gray-950/40 bg-gray-950/50 left-0 right-0 h-full"></div>
            <div
              className="relative z-10 max-w-xl mobile-slide-content"
              style={{
                position: 'absolute',
                bottom: slide.contentPositionBottom, // Dynamic bottom position
                left: slide.contentPositionLeft,
                top: slide.contentPositionTop,
                ...(slide.contentPosition ? { ...slide.contentPosition } : {}) // Apply additional dynamic positioning if provided
              }}
            >
              <p className="mb-6 font-normal">{slide.description}</p>
              <button
                onClick={() => handleButtonClick(slide.buttonLink)}
                className="bg-[#0A78B9] hover:bg-[#2e7aa7] text-white px-6 py-2 rounded-md flex items-center"
              >
                {slide.buttonText} <img alt="right-arrow" src="https://content.qeeb.in/coltec/right-arrow.svg" className="h-2 pl-2"/>
              </button>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
