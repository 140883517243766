import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";

const ApplicationScenarios = ({ images, name }) => {
  return (
    <>
      <div className="md:text-center">
        <p className="md:text-[32px] text-[22px] mb-4 md:mb-8 lg:text-center">
          Application Scenarios
        </p>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 30,
          depth: 100,
          modifier: 3,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false, // Ensures autoplay continues after user interaction
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 2,
          },
          1201: {
            slidesPerView: 2,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper app-coverflow application-slider"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="swiper-slide-app-coverflow">
            <div className="relative">
              <img src={image.url} alt={image.altTag} className="rounded-lg" />

              <p className="text-white font-[500] md:p-[2px_50px_2px_14px] px-3 py-0.5 leading-[24px] absolute bottom-[12px] md:left-[63px] text-sm md:text-[16px] bg-gradient-to-r from-gray-900 via-gray-900 bg-opacity-80">
                {image.name}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default ApplicationScenarios;
