export const updateOrder = async (orderData) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const url = `${baseUrl}/api/ColtecAdmin/UpdateOrder`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error updating order");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
