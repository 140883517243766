import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { getQuotations } from "../utils/adminGetQuotations";
import { getQuotationById } from "../utils/adminGetQuotationById";
import { TbExternalLink } from "react-icons/tb";
// Import the helper functions
import { formatDateDDMMMYYYY } from "../utils/dateHelpers";

const AdminCartHistory = () => {
  // --- Data States ---
  const [cartHistory, setCartHistory] = useState([]);
  const [filteredCartHistory, setFilteredCartHistory] = useState([]);
  const [sortedCartHistory, setSortedCartHistory] = useState([]);
  const [visibleCount, setVisibleCount] = useState(25);
  const [visibleCartHistory, setVisibleCartHistory] = useState([]);
  const [emailFilter, setEmailFilter] = useState("");

  // --- Loading & Error States ---
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // --- Modal States ---
  const [modalOpen, setModalOpen] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  // --- Infinite Scroll States for Modal Details Table ---
  const [visibleDetailsCount, setVisibleDetailsCount] = useState(10);
  const [visibleDetails, setVisibleDetails] = useState([]);

  // Fetch the cart history data from the API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getQuotations();
        setCartHistory(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Update filteredCartHistory whenever cartHistory or emailFilter changes
  useEffect(() => {
    if (emailFilter) {
      setFilteredCartHistory(
        cartHistory.filter((item) => item.email === emailFilter)
      );
    } else {
      setFilteredCartHistory(cartHistory);
    }
    // Reset the visible count whenever the filter changes
    setVisibleCount(25);
  }, [emailFilter, cartHistory]);

  // Sort the filtered data by createdAt (newest first)
  useEffect(() => {
    const sorted = [...filteredCartHistory].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setSortedCartHistory(sorted);
  }, [filteredCartHistory]);

  // Update the visible data based on visibleCount (for infinite scroll)
  useEffect(() => {
    setVisibleCartHistory(sortedCartHistory.slice(0, visibleCount));
  }, [sortedCartHistory, visibleCount]);

  // Infinite scroll handler: Increase visibleCount when scrolled near bottom
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (visibleCount < sortedCartHistory.length) {
        setVisibleCount((prev) => prev + 25);
      }
    }
  };

  // Handle the view details action by fetching quotation details and opening modal
  const handleViewDetails = async (quotationId) => {
    try {
      setModalLoading(true);
      const data = await getQuotationById(quotationId);
      setQuotationDetails(data);
      setModalOpen(true);
    } catch (error) {
      setModalOpen(true);
    } finally {
      setModalLoading(false);
    }
  };

  // When quotationDetails are loaded (or updated), initialize the modal infinite scroll.
  useEffect(() => {
    if (quotationDetails && quotationDetails.quotationDetails) {
      setVisibleDetailsCount(10);
      setVisibleDetails(quotationDetails.quotationDetails.slice(0, 10));
    }
  }, [quotationDetails]);

  // Update visible details when visibleDetailsCount changes.
  useEffect(() => {
    if (quotationDetails && quotationDetails.quotationDetails) {
      setVisibleDetails(
        quotationDetails.quotationDetails.slice(0, visibleDetailsCount)
      );
    }
  }, [visibleDetailsCount, quotationDetails]);

  // Show spinner while loading data
  if (loading) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        <div className="spin-loader"></div>
      </div>
    );
  }

  //  Render error message if fetching fails
  if (error) {
    return <div className="p-4 text-red-500">Error: {error}</div>;
  }

  // Prepare email options for the dropdown filter
  const emailOptions = Array.from(
    new Set(cartHistory.map((item) => item.email))
  );

  // Infinite scroll handler for the modal details table.
  const handleModalDetailsScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (
        quotationDetails &&
        quotationDetails.quotationDetails &&
        visibleDetailsCount < quotationDetails.quotationDetails.length
      ) {
        setVisibleDetailsCount((prev) => prev + 10);
      }
    }
  };

  return (
    <div>
      {/* Email Filter Dropdown */}
      <div className="flex space-x-4 px-8 mb-6">
        <select
          value={emailFilter}
          onChange={(e) => setEmailFilter(e.target.value)}
          className="w-[240px] border text-[15px] flex justify-center items-center px-3 border-gray-200 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">Filter by email</option>
          {emailOptions.map((email) => (
            <option key={email} value={email}>
              {email}
            </option>
          ))}
        </select>
      </div>

      {/* Table container with infinite scroll */}
      <div
        className="overflow-y-auto max-h-[calc(100vh-231px)] scroll-bar"
        onScroll={handleScroll}
      >
        <table className="min-w-full text-sm">
          <thead className="bg-[#EFF4FF] text-[#929EB6] border-b border-[#D1DFFC] sticky top-0">
            <tr>
              <th className="p-4 text-left font-medium">S.No</th>
              <th className="p-4 text-left font-medium">Quotation ID</th>
              <th className="p-4 text-left font-medium">Quotation Number</th>
              <th className="p-4 text-left font-medium">Email</th>
              <th className="p-4 text-left font-medium">Created Date</th>
              <th className="p-4 text-left font-medium">Cart Price</th>
              <th className="p-4 text-left font-medium">Coupon Code</th>
              <th className="p-4 text-left font-medium">Description</th>
              <th className="p-4 text-left font-medium">Discount (%)</th>
            </tr>
          </thead>
          <tbody>
            {visibleCartHistory.length > 0 ? (
              visibleCartHistory.map((item, index) => (
                <tr
                  key={item.quotationID}
                  className={`${
                    index % 2 === 0 ? "bg-[#F4F7FF]" : "bg-[#EBF1FF]"
                  } border-b border-[#DCE2EC]`}
                >
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {index + 1}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {item.quotationID}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {item.quotationNumber}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {item.email}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {formatDateDDMMMYYYY(item.createdAt)}
                  </td>
                  {/* Cart Price with clickable view details icon */}
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    <div className="flex items-center">
                      <p>{item.totalPrice}</p>
                      <span className="mx-2 text-gray-400">|</span>
                      <button
                        onClick={() => handleViewDetails(item.quotationID)}
                        title="View Cart Details"
                        className="inline-block text-blue-500 hover:text-blue-700"
                      >
                        {/* An Eye Icon (SVG) */}
                        <TbExternalLink className="text-lg" />
                      </button>
                    </div>
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {item.couponCode || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {item.description || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {item.discountPercentage || "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="9"
                  className="py-5 px-4 bg-[#F4F7FF]/80 text-center text-gray-500"
                >
                  No records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* --- Slide-in Sidebar (Modal) for Quotation Details --- */}
      <Transition show={modalOpen} as={React.Fragment}>
        <div className="fixed inset-0 flex z-50">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={() => setModalOpen(false)}
          ></div>
          {/* Slide-in Sidebar */}
          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto lg:w-[900px] w-full h-full bg-white shadow-lg overflow-y-auto relative">
              <button
                onClick={() => setModalOpen(false)}
                className="absolute top-5 right-3 text-gray-500 hover:text-gray-700"
              >
                <img
                  alt="close-icon"
                  className="w-5"
                  src="https://content.qeeb.in/coltec/close-icon.svg"
                />
              </button>
              {modalLoading ? (
                <div className="flex justify-center items-center h-32">
                  <div className="spin-loader"></div>
                </div>
              ) : quotationDetails ? (
                <div>
                  <div className="border-b p-4 border-gray-200">
                    <h3 className="text-xl font-bold">
                      Quotation Details for{" "}
                      {quotationDetails.quotationNumber ||
                        quotationDetails.quotationID}
                    </h3>
                  </div>
                  {/* Container for the details table with Infinite Scroll */}
                  <div
                    className="overflow-y-auto max-h-[calc(100vh-250px)] scroll-bar"
                    onScroll={handleModalDetailsScroll}
                  >
                    <table className="min-w-full text-sm">
                      <thead className="bg-[#EFF4FF] text-[#929EB6] border-b border-[#D1DFFC] sticky top-0">
                        <tr>
                          <th className="p-4 text-left font-medium">S.No</th>
                          <th className="p-4 text-left font-medium">
                            Product Name
                          </th>
                          <th className="p-4 text-left font-medium">
                            Quantity
                          </th>
                          <th className="p-4 text-left font-medium">
                            Price Per Unit
                          </th>
                          <th className="p-4 text-left font-medium">
                            Total Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCE2EC]">
                        {visibleDetails && visibleDetails.length > 0 ? (
                          visibleDetails.map((detail, index) => (
                            <tr
                              key={detail.quotationDetailID}
                              className={`${
                                index % 2 === 0
                                  ? "bg-[#F4F7FF]"
                                  : "bg-[#EBF1FF]"
                              }`}
                            >
                              <td className="p-4">{index + 1}</td>
                              <td className="p-4">
                                <div className="flex justify-start items-center space-x-3">
                                  <img
                                    alt={detail.productName}
                                    src={detail.imageUrl}
                                    className="w-10 h-10 rounded-lg"
                                  />
                                  <p>{detail.productName}</p>
                                </div>
                              </td>
                              <td className="p-4">{detail.quantity}</td>
                              <td className="p-4">{detail.pricePerUnit}</td>
                              <td className="p-4">
                                {detail.totalProductPrice}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="5"
                              className="py-5 px-4 text-center text-gray-500"
                            >
                              No details found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                      {/* Grand Total Row */}
                      {quotationDetails.quotationDetails && (
                        <tfoot>
                          <tr className="bg-[#ffffff] border-t border-b">
                            <td
                              colSpan="4"
                              className="p-4 text-right font-bold"
                            >
                              Grand Total
                            </td>
                            <td className="p-4 font-bold">
                              {quotationDetails.quotationDetails
                                .reduce(
                                  (acc, detail) =>
                                    acc + parseFloat(detail.totalProductPrice),
                                  0
                                )
                                .toFixed(2)}
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                </div>
              ) : null}
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </div>
  );
};

export default AdminCartHistory;
