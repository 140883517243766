import React, { useEffect, useState } from "react";
import { getAllCoupons } from "../utils/getAllCoupon";
// Import the helper functions
import { formatDateDDMMMYYYY } from "../utils/dateHelpers";

const GetCoupons = ({ refreshTrigger }) => {
  const [allCoupons, setAllCoupons] = useState([]);
  const [visibleCoupons, setVisibleCoupons] = useState([]);
  // Initially display 25 coupons
  const [visibleCount, setVisibleCount] = useState(25);
  const [loading, setLoading] = useState(false);

  // Fetch the coupons on mount and whenever refreshTrigger changes
  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      try {
        const data = await getAllCoupons();
        // If you want to sort by createdAt descending, uncomment below:
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setAllCoupons(data);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCoupons();
  }, [refreshTrigger]);

  // Update visible coupons whenever allCoupons or visibleCount changes
  useEffect(() => {
    setVisibleCoupons(allCoupons.slice(0, visibleCount));
  }, [allCoupons, visibleCount]);

  // Infinite scroll handler
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // If near the bottom, load more if available
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (visibleCount < allCoupons.length) {
        setVisibleCount((prev) => prev + 25);
      }
    }
  };

  // If data is still loading, show a spinner (replace with your own styling)
  if (loading) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        <div className="spin-loader"></div>
      </div>
    );
  }

  return (
    <>
      <div
        className="overflow-y-auto max-h-[calc(100vh-165px)] scroll-bar"
        onScroll={handleScroll}
        // A scrollable container with a fixed height so onScroll can detect the bottom
      >
        <table className="min-w-full text-sm">
          {/* If you want the header to remain visible as you scroll, add 'sticky top-0' on thead */}
          <thead className="bg-[#EFF4FF] text-[#929EB6] border-b border-[#D1DFFC] sticky top-0">
            <tr>
              <th className="p-4 text-left font-medium">S.No</th>
              <th className="p-4 text-left font-medium">Coupon Code</th>
              <th className="p-4 text-left font-medium">Discount Percentage</th>
              <th className="p-4 text-left font-medium">Status</th>
              <th className="p-4 text-left font-medium">Created At</th>
              <th className="p-4 text-left font-medium">Updated At</th>
              <th className="p-4 text-left font-medium">Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            {/* If there are no visible coupons, show the "No coupons found" row */}
            {visibleCoupons.length === 0 && (
              <tr>
                <td colSpan="6" className="p-4 text-center text-gray-500">
                  No coupons found.
                </td>
              </tr>
            )}

            {visibleCoupons.map((coupon, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-[#F4F7FF]" : "bg-[#EBF1FF]"
                } border-b border-[#DCE2EC]`}
              >
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {index + 1}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {coupon.couponCode}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {coupon.discountPercentage}
                </td>
                <td className="p-4">
                  {coupon.isActive ? (
                    <>
                      <span class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-blue-700/10 ring-inset">
                        Active
                      </span>
                    </>
                  ) : (
                    <>
                      <span class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-red-600/10 ring-inset">
                        Expired
                      </span>
                    </>
                  )}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {formatDateDDMMMYYYY(coupon.createdAt) || "-"}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {formatDateDDMMMYYYY(coupon.updatedAt) || "-"}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {formatDateDDMMMYYYY(coupon.expiryDate) || "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GetCoupons;
