import React from "react";
import Header from "../components/Header";
import BlogPage from "../components/Blogs";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import ScrollToTopButton from "../components/ScrollTop";
import SEO from "../components/SEO";
import WhatsAppMsg from "../components/WhatsApp";

const Blogs = () => {
  return (
    <>
      <div className="blogs-page">
        <SEO
          title="Educational & Corporate Technology Insights | Coltec Blogs"
          description="Explore the latest trends, tips, and innovations in educational and corporate technology. Learn how Coltec's interactive solutions are transforming classrooms."
          keywords="Interactive flat panels, Education technology blogs, Corporate technology insights, Classroom collaboration tools, Digital whiteboards, Smart boards, Interactive displays, Touch screen panels for business, Modern learning technology, Workplace innovation blogs, Technology transformation, EdTech, Corporate collaboration solutions"
          ogTitle="Educational & Corporate Technology Innovations | Coltec Blogs"
          ogDescription="Stay ahead with Coltec's blogs on cutting-edge educational and corporate technology. Discover insights, tips, and solutions for modern learning and business environments."
          ogImage="https://content.qeeb.in/coltec/blogs/section-blog-3.webp"
          ogUrl="https://coltec-global.com/blogs"
          canonicalUrl="https://coltec-global.com/blogs"
          ogImageAlt="Coltec Blogs - Technology Innovations in Education and Corporate"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <Header />
        <div className="md:mt-16 mt-12">
          <BlogPage />
        </div>
        <CTA />
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default Blogs;
