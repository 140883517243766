import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollTop";
import WhatsAppMsg from "../../components/WhatsApp";
import CTA from "../../components/CTA";
import { getUserQuotations } from "../../utils/getUserQuotations";
import { getQuotationById } from "../../utils/adminGetQuotationById";
import { useNavigate, Link } from "react-router-dom";
// Import the helper functions
import { formatDateDDMMMYYYY } from "../../utils/dateHelpers";
import { FiChevronRight } from "react-icons/fi";

const OrderHistory = () => {
  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  // Replace this logic with your authentication context or preferred method.
  useEffect(() => {
    const emailFromStorage = localStorage.getItem("email");
    if (emailFromStorage) {
      setUserEmail(emailFromStorage);
    } else {
      // Optionally, handle the scenario when the email is not available.
      console.error("User email not found.");
      setLoading(false);
    }
  }, []);

  // Fetch quotations only after the user email has been set.
  useEffect(() => {
    if (!userEmail) return;
    const fetchQuotations = async () => {
      try {
        const data = await getUserQuotations(userEmail);
        // Sort the data so that the latest quotation is at the top.
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setQuotations(sortedData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchQuotations();
  }, [userEmail]);

  // Handler for when a quotation card is clicked.
  // It fetches the full quotation details using the quoteId and navigates to the order-details page.
  const handleCardClick = async (quoteId) => {
    try {
      const quotationData = await getQuotationById(quoteId);
      navigate("/products/order-details", {
        state: { quotation: quotationData },
      });
    } catch (err) {
      console.error("Error fetching quotation data:", err);
    }
  };

  return (
    <>
      <div className="about-us-page">
        <Header />
      </div>
      <div className="px-6 lg:px-8 lg:pb-14 pb-10 max-w-7xl pt-24 m-auto">
        {/* Breadcrumb Navigation */}
        <nav className="flex mb-4 text-xs font-medium" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link
                to="/products"
                className="text-gray-900 hover:text-blue-600"
              >
                Home
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <FiChevronRight className="text-sm text-gray-500" />
                <Link
                  to="/products/orders"
                  className="ml-1 text-gray-900 hover:text-blue-600 md:ml-2"
                >
                  My Orders
                </Link>
              </div>
            </li>
          </ol>
        </nav>
        {/* Display a message if there are no orders */}
        {loading && (
          <div className="flex justify-center items-center h-[60vh]">
            {/* Replace with your own spinner or CSS */}
            <div className="spin-loader"></div>
          </div>
        )}
        {!loading && quotations.length === 0 && (
          <p className="mt-12 flex justify-center items-center">
            No Order Found
          </p>
        )}
        {/* Display quotation cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {quotations.map((quotation) => (
            <div
              key={quotation.quotationID}
              className="border cursor-pointer rounded-lg p-4 shadow overflow-hidden hover:shadow-lg hover:bg-purple-50 transition relative"
              onClick={() => handleCardClick(quotation.quotationID)}
            >
              <h2 className="text-xl font-semibold mb-1 quotation-text">
                {quotation.quotationNumber}
              </h2>
              <p className="text-gray-600 font-medium text-sm">
                {formatDateDDMMMYYYY(quotation.createdAt)}
              </p>
              <p className="text-gray-900 text-sm font-semibold">
                <span className="text-gray-600 font-normal">Total Price:</span>{" "}
                {quotation.totalPrice}
              </p>
              <div className="ribbon text-center">
                <p className="text-xs relative rotate-45 p-[5px] mt-[3px] mr-[6px] font-medium ">
                  Discount{" "}
                  {quotation.discountPercentage
                    ? `${quotation.discountPercentage}%`
                    : "-"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ScrollToTopButton />
      <CTA />
      <Footer />
      <WhatsAppMsg />
    </>
  );
};

export default OrderHistory;
