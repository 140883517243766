import React from "react";
import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import CTA from "../../../components/CTA";
import Footer from "../../../components/Footer";
import ScrollToTopButton from "../../../components/ScrollTop";
import ProductSpecification from "../../../components/ProductSpecification";
import riseproductSpecs from "../../../utils/riseSeries";
import ApplicationScenarios from "../../../components/ApplicationScenarios";
import SEO from "../../../components/SEO";
import Testimonials from "../../../components/Testimonials";
import WhatsAppMsg from "../../../components/WhatsApp";
import FAQ from "../../../components/FAQ";
import { faqData } from "../../../utils/riseSeriesFAQ";

const testimonials = [
  {
    image: "https://content.qeeb.in/coltec/testimonials/global-sainik-academy.jpg",
    name: "Sharan Sikenpore",
    altImage: "Coltec Customer Global Sainik Academy",
    designation: "Founder Chairman & CEO",
    description:
      "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/shaheen-group.jpg",
    name: "Mr Qursheed Ahmed",
    altImage: "Coltec Customer Shaheen Group",
    designation: "Academic Councellor",
    description:
      "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/gaudium-logo.png",
    name: "Sudeshna Chatterjee",
    altImage: "Coltec Customer Gaudium School",
    designation: "Head of School",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/iit-madras-logo.png",
    name: "Vikas Sitraman",
    altImage: "Coltec Customer IIT Madras",
    designation: "IIT Madras - Academic Councellor",
    description:
      "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/berkadia.jpg",
    name: "Raj Kumar",
    altImage: "Coltec Customer Berkadia",
    designation: "AV Head",
    description:
      "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/spring-filed.jpg",
    name: "Akash Awasti",
    altImage: "Coltec Customer Spring Filed",
    designation: "Vice Principal",
    description:
      "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/edify-education.png",
    name: "Kishore Pandit",
    altImage: "Coltec Customer Edify Education",
    designation: "Head of School",
    description:
      "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/the-dollar-business.png",
    name: "Ashok Tripathi",
    altImage: "Coltec Customer The Dollar Business",
    designation: "IT Head",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
];

const images = [
  {
    url: "https://content.qeeb.in/coltec/products/rise-large-conference-room.webp",
    name: "Large area Conference Room",
    altTag: "Coltec Interactive Flat Panels For Large area Conference Room",
  },
  {
    url: "https://content.qeeb.in/coltec/products/rise-corporate-training-room.webp",
    name: "Corporate Training Room",
    altTag: "Coltec Interactive Flat Panels For Corporate Training Room",
  },
  {
    url: "https://content.qeeb.in/coltec/products/rise-conference-room.webp",
    name: "Conference Room",
    altTag: "Coltec Interactive Flat Panels For Conference Room",
  },
  {
    url: "https://content.qeeb.in/coltec/products/rise-classroom.webp",
    name: "Class Room",
    altTag: "Coltec Interactive Flat Panels For Class Room",
  },
  {
    url: "https://content.qeeb.in/coltec/products/rise-huddle-room.webp",
    name: "Huddle Room",
    altTag: "Coltec Interactive Flat Panels For Huddle Room",
  },
];

const RiseSeries = () => {
  return (
    <>
      <div className="rise-series">
        <SEO
          title="COLTEC Rise IFPD Series"
          description="Experience the Rise Series interactive flat panels from Coltec: ultra-responsive displays, vivid visuals, and seamless connectivity for dynamic presentations."
          keywords="Interactive flat panels, Interactive displays, Touchscreen displays, Digital whiteboards, Interactive flat panels for classrooms, Business collaboration touchscreens, High-definition interactive displays, Educational touchscreen solutions, Multi-touch interactive panels, Wireless screen sharing displays, Interactive presentation technology, Best Interactive Flat Panel for Schools, Touch Screen Display for Collaborative Learning, Interactive Smart Board for Online Teaching, High-Resolution Interactive Display for Business Meetings, Top Interactive Flat Panels for Classrooms, Multi-Touch Capability Interactive Panel, Zero Bonding Touch Technology Display, Integrated Software Solutions for Teaching, Interactive LED Panel with Low Power Consumption, Durable Interactive Display with Anti-Glare Technology, Interactive Panels for Hybrid Classrooms, Interactive Whiteboard for Conference Rooms, Digital Signage and Interactive Displays for Offices, Interactive Flat Panels for Remote Learning, Educational Interactive Touch Screen Panels, Interactive Flat Panel Supplier in India, Smart Classroom Displays for Indian Schools"
          ogTitle="Interactive Flat Panel | Revolutionize Collaboration and Learning | Coltec Global"
          ogDescription="Experience the Rise Series interactive flat panels from Coltec: ultra-responsive displays, vivid visuals, and seamless connectivity for dynamic presentations."
          ogImage="https://content.qeeb.in/coltec/products/rise-banner-image.webp"
          ogUrl="https://coltec-global.com/products/interactive-flat-panel/rise-series"
          canonicalUrl="https://coltec-global.com/products/interactive-flat-panel/rise-series"
          ogImageAlt="Rise Series Interactive Flat Panel by Coltec"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <Header />
        <div>
          <Banner
            imgSrc="https://content.qeeb.in/coltec/products/rise-banner-image.webp"
            altText="Rise Series Interactive Flat Panels showcasing advanced collaboration features and user-friendly interface, ideal for transforming classrooms and meeting spaces into dynamic environments"
            imgHeight="object-none rise-object md:h-full w-full h-[45vh]"
            heading="Rise Series Interactive Flat Panels: Revolutionizing Collaboration"
            headClasses="max-w-4xl text-[22px] font-semibold tracking-tight leading-[1.7] text-white sm:text-5xl drop-shadow-lg mt-3"
            positionClasses="absolute bottom-8 left-0 lg:left-0 lg:right-0 text-center"
            bannerHeight="h-[48vh] md:h-dvh"
            opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-liner-opacity z-0"
            headerTextClasses="md:text-[44px] text-[29px] font-semibold not-italic text-white leading-2 md:leading-[48px]"
            paragraphTextClasses="md:text-[21px] text-base not-italic font-normal md:leading-[30px] mt-4 text-white max-w-[664px]"
          />
          <div className="bg-linear-grd">
            <div className="px-6 lg:px-8 lg:py-7 py-7 max-w-5xl m-auto">
              <div
                className="grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 xl:gap-0 md:p-6 py-6 bg-white rounded-[32px] shadow-rise"
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
              >
                <div className="md:border-r md:border-[#ABABAB] md:border-dashed">
                  <div className="flex items-center flex-col h-24 md:mb-0 mb-6">
                    <img
                      alt="Coltec Interactive Flat Panel Built-in 8 Microphone Array"
                      src="https://content.qeeb.in/coltec/products/pink-array-mic-icon.svg"
                      className="mb-8"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Built-in 8
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      microphone array
                    </p>
                  </div>
                </div>
                <div className="md:border-r md:border-[#ABABAB] md:border-dashed">
                  <div className="flex items-center flex-col h-24 md:mb-0 mb-6">
                    <img
                      alt="Coltec Interactive Flat Panel  Intelligent Touch Point"
                      src="https://content.qeeb.in/coltec/products/intelligence.svg"
                      className="mb-6 h-9"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Intelligent
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      touch point
                    </p>
                  </div>
                </div>
                <div className="md:border-r md:border-[#ABABAB] md:border-dashed">
                  <div className="flex items-center flex-col h-24">
                    <img
                      alt="Coltec Interactive Flat Panel Dual Stereo Speakers"
                      src="https://content.qeeb.in/coltec/products/dual.svg"
                      className="h-8 mb-6"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Dual
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      stereo speakers
                    </p>
                  </div>
                </div>
                <div>
                  <div className="flex items-center flex-col h-24">
                    <img
                      alt="Coltec Interactive Flat Panel Dual Operating Systems"
                      src="https://content.qeeb.in/coltec/products/dual-os.svg"
                      className="mb-4"
                    />
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Dual
                    </p>
                    <p className="text-[#262626] text-sm not-italic leading-5">
                      Operating Systems
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grd-linear">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div
              className="max-w-2xl mx-auto lg:text-center"
              data-aos="fade-left"
            >
              <p className="text-2xl font-medium mb-5">
                Ultra-Clear 4K Display
              </p>
              <p className="text-sm font-normal">
                Experience stunning 4K clarity and vibrant 400-nit brightness
                from any angle. With toughened glass protection, our display
                delivers unmatched durability and breathtaking visuals—perfect
                for corporate & education spaces.
              </p>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mt-8">
              <div data-aos="fade-right">
                <img
                  alt="Coltec Rise Series Interactive Flat Panel showcasing a vivid cosmic nebula in 4K resolution, perfect for immersive presentations and collaborative learning environments."
                  src="https://content.qeeb.in/coltec/products/rise-ultra-clear-4k-display.webp"
                />
              </div>
              <div
                className="md:block grid grid-cols-2 lg:pt-[53px]"
                data-aos="fade-left"
              >
                <div className="md:text-right mb-4">
                  <p className="text-pink-grd text-[30px] font-semibold not-italic">
                    4K
                  </p>
                  <p className="inline-flex max-w-[170px] text-sm font-normal not-italic text-gray-800">
                    100% NTSC Colour Gamut Display
                  </p>
                </div>
                <div className="md:text-right mb-4">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    400 nits
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Brightness
                  </span>
                </div>
                <div className="md:text-right mb-4">
                  <p className="text-pink-grd text-[30px] font-semibold not-italic">
                    178°
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Viewing Angle
                  </span>
                </div>
                <div className="md:text-right">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    Toughened
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Glass Screen
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div
              className="max-w-2xl mx-auto lg:text-center"
              data-aos="fade-down"
            >
              <p className="text-2xl font-medium mb-5">
                Precision Infrared Touch Technology
              </p>
              <p className="text-sm font-normal">
                Experience flawless interaction with our advanced infrared touch
                technology, featuring 20 touch points for seamless
                collaboration. With a touch precision of ±1mm, every tap and
                swipe is incredibly accurate, ensuring a responsive and
                intuitive experience for all users.
              </p>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-10 mt-8">
              <div data-aos="fade-right">
                <img
                  alt="Coltec Rise Series Interactive Flat Panel showcasing a digital whiteboard interface for dynamic presentations and collaboration, perfect for classrooms and corporate environments."
                  src="https://content.qeeb.in/coltec/products/rise-precision-infrared-touch-tech.webp"
                />
              </div>
              <div
                className="md:block grid grid-cols-2 lg:pt-[45px]"
                data-aos="fade-left"
              >
                <div className="md:text-right mb-4">
                  <p className="text-pink-grd text-[30px] font-semibold not-italic">
                    Infrared
                  </p>
                  <p className="inline-flex max-w-[170px] text-sm font-normal not-italic text-gray-800">
                    Touch Technology
                  </p>
                </div>
                <div className="md:text-right mb-4">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    20
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Touch Points
                  </span>
                </div>
                <div className="md:text-right">
                  <p className="text-orange-grd text-[30px] font-semibold not-italic">
                    ±1mm
                  </p>
                  <span className="text-sm font-normal not-italic text-gray-800">
                    Touch Precision
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-grd-linear">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
            <div
              className="max-w-2xl mx-auto lg:text-center"
              data-aos="fade-right"
              data-aos-offset="500"
              data-aos-easing="ease-in-sine"
            >
              <p className="text-2xl font-medium mb-5">
                Powerful Performance and Connectivity
              </p>
              <p className="text-sm font-normal">
                Unleash the power of an Octa-Core Cortex processor combined with
                up to 12GB RAM and 256GB ROM for lightning-fast performance.
                With versatile ports and dual OS support (Android 14 and
                Windows), seamlessly switch between operating systems and
                connect all your devices for a truly dynamic experience.
              </p>
            </div>
            <div className="grid grid-cols-12 mt-10">
              <div className="col-span-12">
                <img
                  className="w-full"
                  alt="Coltec Octa-core processor with 12/256 GB RAM/ROM, dual OS, and 15 ports for Coltec interactive flat panels"
                  src="https://content.qeeb.in/coltec/products/rise-powerful-performance-connectivity.webp"
                />
              </div>
              <div className="col-span-12 mt-10">
                <div className="flex justify-between flex-wrap gap-8">
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-pink-grd text-[30px] font-semibold not-italic">
                      Octa-Core
                    </p>
                    <p className="inline-flex max-w-[170px] text-sm font-normal not-italic text-gray-800">
                      Processor
                    </p>
                  </div>
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-orange-grd text-[30px] font-semibold not-italic">
                      12/256 GB
                    </p>
                    <span className="text-sm font-normal not-italic text-gray-800">
                      Upto RAM/ROM
                    </span>
                  </div>
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-orange-grd text-[30px] font-semibold not-italic">
                      Dual
                    </p>
                    <span className="text-sm font-normal not-italic text-gray-800">
                      Operating Systems
                    </span>
                  </div>
                  <div
                    className="text-right"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <p className="text-orange-grd text-[30px] font-semibold not-italic">
                      15
                    </p>
                    <span className="text-sm font-normal not-italic text-gray-800">
                      Connection Ports
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <ApplicationScenarios images={images} />
          </div>
        </div>
        <div className="px-6 lg:px-8 lg:pb-14 pb-10 max-w-7xl m-auto">
          <ProductSpecification specs={riseproductSpecs} />
        </div>
        <div className="px-6 lg:px-8 lg:pb-14 pb-10 max-w-7xl m-auto">
          <FAQ data={faqData} />
        </div>
        <div className="bg-[#f5f5f5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <Testimonials testimonials={testimonials} />
          </div>
        </div>

        <CTA />
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default RiseSeries;
