import React from "react";
import { useLocation, Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { FiChevronRight } from "react-icons/fi";
import { FaFileInvoice } from "react-icons/fa6";
import { LuDownload, LuCheck } from "react-icons/lu";
import { formatDateDDMMMYYYY } from "../../utils/dateHelpers";

const OrderDetails = () => {
  const location = useLocation();

  // Retrieve the quotation data from the state passed during navigation.
  const { quotation } = location.state || {};

  const allProductsPrice =
    quotation && quotation.quotationDetails
      ? quotation.quotationDetails.reduce(
          (acc, detail) => acc + parseFloat(detail.totalProductPrice),
          0
        )
      : 0;
  // Define order statuses (timeline steps) and parse the current status.
  const statuses = [
    "Order Received",
    "Order Processing",
    "Ready to Dispatch",
    "In Transit",
    "Out for Delivery",
    "Delivered",
  ];

  const currentStatus = parseInt(quotation.quoteStatus, 10);

  return (
    <div className="min-h-screen about-us-page">
      <Header />
      <div className="px-6 lg:px-8 lg:pb-14 pb-10 pt-24 max-w-7xl m-auto">
        {/* Breadcrumb Navigation */}
        <nav className="flex mb-4 text-xs font-medium" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link
                to="/products"
                className="text-gray-900 hover:text-blue-600"
              >
                Home
              </Link>
            </li>
            <li>
              <div className="flex items-center">
                <FiChevronRight className="text-sm text-gray-500" />
                <Link
                  to="/products/orders"
                  className="ml-1 text-gray-900 hover:text-blue-600 md:ml-2"
                >
                  My Orders
                </Link>
              </div>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <FiChevronRight className="text-sm text-gray-500" />

                <span className="ml-1 text-gray-500 md:ml-2">
                  {quotation.quotationNumber}
                </span>
              </div>
            </li>
          </ol>
        </nav>

        {/* Product Details */}
        <div className="grid grid-cols-12 gap-6">
          <div className="lg:col-span-8 col-span-full space-y-6">
            {quotation.quotationDetails &&
              quotation.quotationDetails.map((detail) => {
                // Use detail.panelSize if available, otherwise try to extract a number (e.g. "65" or "75") from the product name.
                const panelSize =
                  detail.panelSize || detail.productName.match(/\d+/)?.[0];

                return (
                  <div
                    key={detail.quotationDetailID}
                    className="border rounded-lg overflow-hidden shadow hover:shadow-lg transition p-6"
                  >
                    <div className="flex lg:flex-row flex-col-reverse justify-between">
                      <div className="mt-4 lg:mt-0">
                        <h3 className="md:text-base text-sm font-semibold mb-1">
                          {detail.productName}
                        </h3>
                        <div className="flex space-x-2 mb-[2px] items-center">
                          <p className="text-sm font-medium">
                            <span className="text-sm text-gray-500 font-normal">
                              Quantity:
                            </span>{" "}
                            {detail.quantity}
                          </p>
                          <span className="text-gray-400">|</span>
                          {panelSize && (
                            <p className="text-sm font-medium">
                              <span className="text-sm text-gray-500 font-normal">
                                Panel Size:
                              </span>{" "}
                              {panelSize}
                            </p>
                          )}
                        </div>
                        <p className="text-sm font-medium mb-[2px]">
                          <span className="text-sm text-gray-500 font-normal">
                            Price Per Unit:
                          </span>{" "}
                          {detail.pricePerUnit}
                        </p>
                        <p className="text-sm font-medium">
                          <span className="text-sm text-gray-500 font-normal">
                            Total Price:
                          </span>{" "}
                          {detail.totalProductPrice}
                        </p>
                      </div>
                      <div>
                        <img
                          src={detail.imageUrl}
                          alt={detail.productName}
                          className="w-full lg:h-32 h-48 rounded-lg object-cover"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* Order Tracking Timeline */}
            <div className="bg-white shadow-md rounded-lg border border-gray-200 p-3 md:p-4 mb-4">
              <h4 className="text-sm font-medium text-gray-800 mb-4">
                Order Tracking
              </h4>
              <div className="relative">
                {/* Gray progress line */}
                <div className="absolute top-3 left-5 md:left-12 w-[85%] md:w-[91%] h-[2.5px] bg-gray-200"></div>
                {/* Blue progress line based on currentStatus */}
                <div
                  className="absolute top-3 left-5  md:left-12 w-[85%] md:w-[91%] h-[2.5px] bg-green-600"
                  style={{
                    width: `${
                      ((currentStatus - 1) / (statuses.length - 1)) * 100
                    }%`,
                  }}
                ></div>
                <div className="flex justify-between flex-nowrap overflow-x-auto">
                  {statuses.map((status, index) => {
                    const stepNumber = index + 1;
                    const isCompleted = stepNumber <= currentStatus;
                    return (
                      <div
                        key={index}
                        className="flex flex-col md:items-center relative z-10"
                      >
                        <div
                          className={`w-[25px] h-[25px] rounded-full flex items-center justify-center ${
                            isCompleted
                              ? "bg-green-600 text-white"
                              : "bg-white border text-gray-900"
                          }`}
                        >
                          {isCompleted ? (
                            <LuCheck className="text-base" />
                          ) : (
                            <span className="text-xs">{stepNumber}</span>
                          )}
                        </div>
                        <p className="whitespace-nowrap pr-3 mt-2 text-[9px] md:text-xs md:text-center">
                          {status}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* Order Summary */}
          <div className="lg:col-span-4 col-span-full">
            {quotation.invoiceUrl && (
              <div className="bg-white shadow-md rounded-lg p-4 border border-gray-200 mb-4">
                <Link
                  to={quotation.invoiceUrl}
                  target="_blank"
                  className="flex justify-between items-center"
                >
                  <div className="flex items-center font-medium">
                    <FaFileInvoice className="text-xl text-blue-600 mr-2" />
                    Invoice download
                  </div>
                  <div className="bg-black/10 hover:bg-black text-gray-500 transition-all duration-300 hover:text-white w-9 h-9 rounded-full flex justify-center items-center">
                    <LuDownload className="text-lg" />
                  </div>
                </Link>
              </div>
            )}

            <div className="bg-white shadow-md rounded-lg p-4 border border-gray-200 mb-4">
              <p className="text-sm font-medium">{quotation.quotationNumber}</p>
              <p className="text-sm font-medium my-1">{quotation.email}</p>
              <p className="text-sm font-medium">
                {formatDateDDMMMYYYY(quotation.createdAt)}
              </p>
            </div>

            <div className="bg-white shadow-md rounded-lg border border-gray-200 ">
              <h4 className="border-b border-gray-200 font-medium py-2 px-4 text-[13px] text-gray-600">
                Price Details
              </h4>
              <div className="p-4">
                <div className="flex justify-between mb-1">
                  <p className="text-gray-500 text-sm">Coupon Code</p>{" "}
                  <span className="text-sm font-medium">
                    {quotation.couponCode || "-"}
                  </span>
                </div>
                <div className="flex justify-between mb-1">
                  <p className="text-gray-500 text-sm">Discount Percentage</p>{" "}
                  <span className="text-sm font-medium">
                    {" "}
                    {quotation.discountPercentage
                      ? `${quotation.discountPercentage}%`
                      : "-"}
                  </span>
                </div>
                <div className="flex justify-between mb-1">
                  <p className="text-gray-500 text-sm">Selling price</p>
                  <span className="text-sm font-medium">
                    ₹{allProductsPrice.toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between mb-1">
                  <p className="text-gray-500 text-sm">Extra Discount</p>
                  <span className="text-sm font-medium line-through">
                    ₹
                    {(
                      parseFloat(quotation.totalPrice) *
                      (parseFloat(quotation.discountPercentage) / 100)
                    ).toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between items-center border-t border-gray-300 border-dashed pt-2 mt-2">
                  <p className="text-gray-500 text-sm">
                    Grand Total Price{" "}
                    <span className="text-[11px] font-normal text-gray-400">
                      (incl GST)
                    </span>{" "}
                  </p>{" "}
                  <span className="text-sm font-medium">
                    ₹{quotation.totalPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OrderDetails;
