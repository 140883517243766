import React, { useEffect, useState } from "react";
import { formatDateDDMMMYYYY } from "../utils/dateHelpers";
import { getOrderNumbers } from "../utils/getOrderNumbers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateOrder } from "../utils/updateOrder";

// Map the numeric status codes (as strings) to display values.
const statusOptions = {
  1: "Order Received",
  2: "Order Processing",
  3: "Ready to Dispatch",
  4: "In Transit",
  5: "Out for Delivery",
  6: "Delivered",
};

const AdminOrderHistory = () => {
  const [allOrders, setAllOrders] = useState([]);
  const [visibleOrders, setVisibleOrders] = useState([]);
  // Initially display 25 orders; adjust the count as needed.
  const [visibleCount, setVisibleCount] = useState(25);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State to hold per-order status update info.
  // Each key is order.quotationID and its value is an object:
  // { previousStatus, newStatus, invoiceNo, invoiceLink }
  const [orderUpdates, setOrderUpdates] = useState({});

  // Fetch orders on component mount.
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const data = await getOrderNumbers();
        // Optionally, sort the orders by createdAt descending.
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAllOrders(data);
      } catch (err) {
        console.error("Error fetching orders:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Update visible orders whenever allOrders or visibleCount changes.
  useEffect(() => {
    setVisibleOrders(allOrders.slice(0, visibleCount));
  }, [allOrders, visibleCount]);

  // Infinite scroll handler: load more orders when scrolled near bottom.
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (visibleCount < allOrders.length) {
        setVisibleCount((prev) => prev + 25);
      }
    }
  };

  // Handler for order status change.
  const handleStatusChange = (order, e) => {
    const newStatus = e.target.value;
    setOrderUpdates((prev) => ({
      ...prev,
      [order.quotationID]: {
        // Save the previous status (if not already saved) from the order.
        previousStatus:
          prev[order.quotationID]?.previousStatus || order.quoteStatus,
        newStatus: newStatus,
        // Preserve any entered invoice data.
        invoiceNo: prev[order.quotationID]?.invoiceNo || "",
        invoiceLink: prev[order.quotationID]?.invoiceLink || "",
       // If already submitted, keep that flag.
       submitted: prev[order.quotationID]?.submitted || false,
      },
    }));
  };

  // Handler for Invoice No change.
  const handleInvoiceNoChange = (orderId, e) => {
    const invoiceNo = e.target.value;
    setOrderUpdates((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        invoiceNo,
      },
    }));
  };

  // Handler for Invoice Link change.
  const handleInvoiceLinkChange = (orderId, e) => {
    const invoiceLink = e.target.value;
    setOrderUpdates((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        invoiceLink,
      },
    }));
  };

  // Handler for submitting the update for a specific order.
  const handleSubmitStatusChange = async (orderId) => {
    const update = orderUpdates[orderId] || {};
    const newStatus = update.newStatus;
    // If the selected status is "3" or "4", validate invoice fields.
    if (newStatus === "3" || newStatus === "4") {
      if (!update.invoiceNo.trim() || !update.invoiceLink.trim()) {
        toast.error(
          "Invoice No and Invoice URL are required."
        );
        return;
      }
    }
    // Build the payload
    const payload = {
      quoteId: orderId, // assuming order.quotationID is used as quoteId
      statusId: newStatus,
      invoiceNumber: update.invoiceNo,
      invoiceUrl: update.invoiceLink,
    };

    try {
      await updateOrder(payload);
      toast.success("Order update submitted successfully!");
      // Optionally, clear the update for the order upon success.
      setOrderUpdates((prev) => ({
        ...prev,
        [orderId]: {
          ...prev[orderId],
          submitted: true,
          // Optionally, add a flag to disable status if delivered.
          newStatus: newStatus,
        },
      }));
    } catch (error) {
      toast.error("Failed to update order: " + error.message);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        {/* Replace with your preferred spinner */}
        <div className="spin-loader"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-[60vh] text-red-500">
        Error: {error}
      </div>
    );
  }

  return (
    <>
      <div
        className="overflow-y-auto max-h-[calc(100vh-165px)] scroll-bar"
        onScroll={handleScroll}
      >
        <table className="min-w-full text-sm">
          <thead className="bg-[#EFF4FF] text-[#929EB6] border-b border-[#D1DFFC] sticky top-0">
            <tr>
              <th className="p-4 text-left font-medium">S.No</th>
              <th className="p-4 text-left font-medium">Quotation Number</th>
              <th className="p-4 text-left font-medium">Email</th>
              <th className="p-4 text-left font-medium">Created At</th>
              <th className="p-4 text-left font-medium">Total Price</th>
              <th className="p-4 text-left font-medium">Order Status</th>
              <th className="p-4 text-left font-medium">Invoice No</th>
              <th className="p-4 text-left font-medium">Invoice Url</th>
              <th className="p-4 text-left font-medium">Action</th>
            </tr>
          </thead>
          <tbody>
            {visibleOrders.length === 0 && (
              <tr>
                <td colSpan="9" className="p-4 text-center text-gray-500">
                  No orders found.
                </td>
              </tr>
            )}
            {visibleOrders.map((order, index) => {
              // Get update info for this order if available; if not, set default values.
              const orderUpdate = orderUpdates[order.quotationID] || {
                invoiceNo: order.invoiceNumber || "",
                invoiceLink: order.invoiceUrl || "",
                newStatus: order.quoteStatus.toString(),
                previousStatus: order.quoteStatus,
                submitted: false,
              };

              return (
                <tr
                  key={order.quotationID}
                  className={`${
                    index % 2 === 0 ? "bg-[#F4F7FF]" : "bg-[#EBF1FF]"
                  } border-b border-[#DCE2EC]`}
                >
                  <td className="p-4 text-[#193B68]">{index + 1}</td>
                  <td className="p-4 text-[#193B68]">
                    {order.quotationNumber}
                  </td>
                  <td className="p-4 text-[#193B68]">{order.email}</td>
                  <td className="p-4 text-[#193B68]">
                    {order.createdAt
                      ? formatDateDDMMMYYYY(order.createdAt)
                      : "-"}
                  </td>
                  <td className="p-4 text-[#193B68]">{order.totalPrice}</td>
                  {/* Order Status Column */}
                  <td className="p-4 text-[#193B68]">
                    <select
                      id={`status-${order.quotationID}`}
                      value={orderUpdate.newStatus}
                      onChange={(e) => handleStatusChange(order, e)}
                     // Disable the status select if the update has been submitted
                      // and the new status is "6" (Delivered).
                      disabled={orderUpdate.submitted && orderUpdate.newStatus === "6"}
                      className="p-2 border rounded-lg w-[160px] text-sm border-gray-200"
                    >
                      {Object.entries(statusOptions).map(([value, label]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    {/* Hidden input to store the previous status */}
                    <input type="hidden" value={orderUpdate.previousStatus} />
                  </td>
                  {/* Invoice No Column - Always Rendered */}
                  <td className="p-4 text-[#193B68]">
                    <input
                      type="text"
                      placeholder="Invoice No"
                      value={orderUpdate.invoiceNo}
                      onChange={(e) =>
                        handleInvoiceNoChange(order.quotationID, e)
                      }
                      disabled={orderUpdate.submitted}
                      className="p-2 border rounded-lg w-[160px] text-sm border-gray-200"
                    />
                  </td>
                  {/* Invoice Url Column - Always Rendered */}
                  <td className="p-4 text-[#193B68]">
                    <input
                      type="text"
                      placeholder="Invoice Link"
                      value={orderUpdate.invoiceLink}
                      onChange={(e) =>
                        handleInvoiceLinkChange(order.quotationID, e)
                      }
                      disabled={orderUpdate.submitted}
                      className="p-2 border rounded-lg w-[200px] text-sm border-gray-200"
                    />
                  </td>
                  {/* Action Column - Always Rendered */}
                  <td className="p-4 text-[#193B68]">
                    <button
                      onClick={() =>
                        handleSubmitStatusChange(order.quotationID)
                      }
                      disabled={orderUpdate.submitted}
                      className="py-1 px-4 bg-blue-500 text-white rounded"
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminOrderHistory;
