import axios from "axios";
const baseUrl = process.env.REACT_APP_API_URL;
export const getOrderNumbers = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/ColtecAdmin/GetOrderNumbers`);
    return response.data;
  } catch (error) {
    console.error("Error fetching order numbers:", error);
    return [];
  }
};
