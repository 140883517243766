import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/Home";
import Education from "./pages/solutions/Education";
import Corporate from "./pages/solutions/Corporate";
import AboutUs from "./pages/AboutUs";
import Connect from "./pages/Connect";
import EvokeSeries from "./pages/products/interactive-flat-panel/EvokeSeries";
import RiseSeries from "./pages/products/interactive-flat-panel/RiseSeries";
import InspireSeries from "./pages/products/interactive-flat-panel/InspireSeries";
import BlogDetails from "./pages/BlogDetails";
import Blogs from "./pages/Blogs";
import Thanks from "./pages/Thanks";
import Media from "./pages/Media";
import InteractiveFlatPanel from "./pages/blogs/InteractiveFlatPanel";
import LandingInteractiveFlatPanel from "./pages/landing-pages/InteractiveFlatPanel";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";
import Products from "./pages/products/Products";
import ProductDetails from "./pages/products/ProductDetails";
import ProductsCart from "./pages/products/ProductsCart";
import RefundReturnPolicy from "./pages/RefundReturnPolicy";
import EWastePolicy from "./pages/EWastePolicy";
import TermsOfUse from "./pages/TermsOfUse";
import AdminPage from "./pages/AdminPage";
import OrderHistory from "./pages/products/OrderHistory";
import OrderDetails from "./pages/products/OrderDetails";

// Custom hook to track page views
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]); // Track page view on route change
};

const AppRoutes = () => {
  usePageTracking(); // Place tracking hook here

  return (
    <>
      <Home />
      {/* All other components and their routes */}
    </>
  );
};

const App = () => {
  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/media",
      element: <Media />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/products/cart",
      element: <ProductsCart />,
    },
    {
      path: "/products/orders",
      element: <OrderHistory />,
    },
    {
      path: "/products/order-details",
      element: <OrderDetails />,
    },
    {
      path: "/product-details/:slug",
      element: <ProductDetails />,
    },
    {
      path: "/products/interactive-flat-panel/evoke-series",
      element: <EvokeSeries />,
    },
    {
      path: "/products/interactive-flat-panel/rise-series",
      element: <RiseSeries />,
    },
    {
      path: "/products/interactive-flat-panel/inspire-series",
      element: <InspireSeries />,
    },
    {
      path: "/solutions/education",
      element: <Education />,
    },
    {
      path: "/solutions/corporate",
      element: <Corporate />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/connect",
      element: <Connect />,
    },
    {
      path: "/thanks",
      element: <Thanks />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/blogs/:title",
      element: <BlogDetails />,
    },
    {
      path: "/blogs/interactive-flat-panel",
      element: <InteractiveFlatPanel />,
    },
    {
      path: "/coltec-smart-interactive-flat-panel-for-classroom",
      element: <LandingInteractiveFlatPanel />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/cookies-policy",
      element: <CookiesPolicy />,
    },
    {
      path: "/refund-return-policy",
      element: <RefundReturnPolicy />,
    },
    {
      path: "/e-waste-policy",
      element: <EWastePolicy />,
    },
    {
      path: "/terms-of-use",
      element: <TermsOfUse />,
    },
    {
      path: "/admin",
      element: <AdminPage />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router}>
        <AppRoutes /> {/* Place routes inside Router context */}
      </RouterProvider>
    </div>
  );
};

export default App;
