import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
// Create a new discount coupon
export const getQuotationById = async (quoteId) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/ColtecAdmin/GetQuotationById?quoteId=${quoteId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error creating coupon:", error);
    throw error;
  }
};
