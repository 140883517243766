import React from "react";
// Import Swiper React components and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const ProductSpecifications = ({ specs }) => {
  // Extract all unique labels across different sizes
  const keys = new Set();
  Object.values(specs).forEach((sizeSpecs) => {
    sizeSpecs.forEach(({ label }) => keys.add(label));
  });
  const uniqueKeys = Array.from(keys);

  return (
    <>
      <h2 className="md:text-[32px] text-[22px] mb-5 md:mb-10 lg:text-center">
        Product Specifications
      </h2>

      {/* Mobile view: Slider (visible below 768px) */}
      <div className="xl:hidden">
        <Swiper
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper product-specs"
        >
          {Object.keys(specs).map((size) => (
            <SwiperSlide key={size}>
              <div className="mb-6 p-4 border border-gray-200 shadow-md rounded-lg">
                <h3 className="font-medium text-[14px] mb-2 border-b pb-2">
                  Diagonal Size (inch) {size}
                </h3>
                {uniqueKeys.map((label) => {
                  const specForSize = specs[size].find(
                    (spec) => spec.label === label
                  );
                  return (
                    <div
                      key={label}
                      className="flex justify-between items-center mb-2 border-b pb-2"
                    >
                      <span className="font-medium text-[11px] w-1/2">
                        {label}:
                      </span>
                      <span className="text-[11px] w-1/2 text-right">
                        {specForSize ? specForSize.value : "N/A"}
                      </span>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Desktop view: Default card layout (visible at 768px and above) */}
      {/* Display the sizes as headers */}
      <div className="hidden xl:grid xl:grid-cols-5">
        <div className="font-medium text-[12px] border border-l-0 border-r-0 border-gray-300 p-5 pl-0">
          Diagonal Size (inch)
        </div>
        {Object.keys(specs).map((size) => (
          <div
            key={size}
            className="font-medium text-[11px] border border-l-0 border-r-0 border-gray-300 p-5 pl-0"
          >
            {size}
          </div>
        ))}
      </div>

      {/* Display the specification labels and their values */}
      {uniqueKeys.map((label) => (
        <div key={label} className="hidden xl:grid xl:grid-cols-5">
          {/* Label column */}
          <div className="font-medium text-[12px] border-b border-l-0 border-r-0 border-gray-300 p-5 pl-0">
            {label}
          </div>

          {/* Values for each size */}
          {Object.keys(specs).map((size) => {
            const specForSize = specs[size].find(
              (spec) => spec.label === label
            );
            return (
              <div
                key={size}
                className="font-medium text-[11px] border-b border-l-0 border-r-0 border-gray-300 p-5 pl-0"
              >
                {specForSize ? specForSize.value : "N/A"}
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default ProductSpecifications;
