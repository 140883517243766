import React, { useEffect, useRef, useState, useMemo, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { blogData } from "../utils/blogData";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import ScrollToTopButton from "../components/ScrollTop";
import SEO from "../components/SEO";
import WhatsAppMsg from "../components/WhatsApp";
import { addBlogComment } from "../utils/blogComments";
import { getBlogDetails } from "../utils/getBlogDetails";
import { blogLike } from "../utils/likeBlogs"; // Your provided API function
import { formatCommentDate } from "../utils/formatDate";
import { UserContext } from "../utils/UserContext";
import { FiShare } from "react-icons/fi";
import { IoIosLink } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
  AiOutlineComment,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BlogDetails = () => {
  const { title } = useParams();
  const blogIndex = blogData.findIndex((b) => b.slug === title);
  const blog = blogData[blogIndex];
  const { user, openSignInModal } = useContext(UserContext); // Access user from context
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Manage dropdown state
  const [showEbookBanner, setShowEbookBanner] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
  };

  // Determine previous and next blogs
  const prevBlog = blogIndex > 0 ? blogData[blogIndex - 1] : null;
  const nextBlog =
    blogIndex < blogData.length - 1 ? blogData[blogIndex + 1] : null;

  // State for controlling the popup visibility
  const [showSuggestion, setShowSuggestion] = useState(true);
  // We will track intersection on the CTA section
  const ctaRef = useRef(null);
  // Initialize username based on user authentication
  const [username, setUsername] = useState(user ? user.name : "");
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // States for fetched comments
  const [comments, setComments] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState(null);

  // States for likes and dislikes
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [isLikeActive, setIsLikeActive] = useState(false);
  const [isDislikeActive, setIsDislikeActive] = useState(false);
  const [isProcessingLike, setIsProcessingLike] = useState(false);
  const [isProcessingDislike, setIsProcessingDislike] = useState(false);

  // **New State Variables for Animation**
  const [likeAnimation, setLikeAnimation] = useState(false);
  const [dislikeAnimation, setDislikeAnimation] = useState(false);

  // **New State Variable for Comment Section Visibility**
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        setShowEbookBanner(true);
      } else {
        setShowEbookBanner(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Updated function: if the user is not logged in, open the sign-in modal
  const handleEbookClick = () => {
    if (!user) {
      openSignInModal();
      return;
    }
    // If the user is logged in, trigger the download
    window.open(
      "https://content.qeeb.in/coltec/A-BEGINNER'S-GUIDEBOOK-TO-BUYING-IFPD.pdf",
      "_blank"
    );
    const link = document.createElement("a");
    link.href =
      "https://content.qeeb.in/coltec/A-BEGINNER'S-GUIDEBOOK-TO-BUYING-IFPD.pdf";
    link.download = "A-BEGINNER'S-GUIDEBOOK-TO-BUYING-IFPD.pdf"; // any filename you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch comments, likes, and dislikes when the blog changes
  useEffect(() => {
    const fetchBlogDetails = async () => {
      if (!blog) return;

      setIsLoadingComments(true);
      setCommentsError(null);

      try {
        const blogDetails = await getBlogDetails(blog.id);
        // Sort comments in descending order based on createdAt
        const sortedComments = (blogDetails.comments || []).sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setComments(sortedComments);
        setLikes(blogDetails.likes || 0);
        setDislikes(blogDetails.dislikes || 0);
        // Set active states based on blogDetails if needed
        // Assuming blogDetails does not provide user-specific like/dislike status
        setIsLikeActive(false);
        setIsDislikeActive(false);
      } catch (error) {
        setCommentsError(error.message || "Failed to load comments.");
      } finally {
        setIsLoadingComments(false);
      }
    };

    fetchBlogDetails();
  }, [blog]);

  // Update username if user changes (e.g., signs in/out)
  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      setUsername(user.name);
    } else {
      localStorage.removeItem("user");
      setUsername("");
    }
  }, [user]);

  // Intersection Observer to detect when CTA is visible
  useEffect(() => {
    const targetNode = ctaRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowSuggestion(entry.isIntersecting);
      },
      { threshold: 0.8 }
    );

    if (targetNode) {
      observer.observe(targetNode);
    }

    return () => {
      if (targetNode) {
        observer.unobserve(targetNode);
      }
    };
  }, []);

  // Function to get two random blogs, excluding the current blog
  const getRandomTwoBlogs = () => {
    // Exclude the current blog
    const filteredBlogs = blogData.filter((b) => b.slug !== title);

    // If there are less than two blogs, return all
    if (filteredBlogs.length <= 2) {
      return filteredBlogs;
    }

    const selectedBlogs = [];
    const usedIndices = new Set();

    while (selectedBlogs.length < 2) {
      const randomIndex = Math.floor(Math.random() * filteredBlogs.length);
      if (!usedIndices.has(randomIndex)) {
        usedIndices.add(randomIndex);
        selectedBlogs.push(filteredBlogs[randomIndex]);
      }
    }

    return selectedBlogs;
  };
  // Memoize the latestTwoBlogs to avoid re-selection on every render
  // eslint-disable-next-line
  const latestTwoBlogs = useMemo(() => getRandomTwoBlogs(), [title]);

  // Handle comment submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      // Trigger the existing sign-in modal from context
      openSignInModal();
      toast.info("Please sign in to post a comment.", {
        position: "top-right",
        autoClose: 2000, // 2 seconds
      });
      return;
    }

    setIsSubmitting(true);

    // Use user.name if signed in, else use the entered username
    const commentUsername = user ? user.name : username;
    console.log(blog.title, "blog title");
    try {
      await addBlogComment(
        blog.id,
        commentUsername,
        comment,
        blog.title,
        blog.category
      );
      toast.success("Comment posted successfully!", {
        position: "top-right",
        autoClose: 2000, // 2 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setUsername("");
      setComment("");
      // Refresh comments after successful submission
      const blogDetails = await getBlogDetails(blog.id);
      setComments(
        (blogDetails.comments || []).sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
      setLikes(blogDetails.likes || 0);
      setDislikes(blogDetails.dislikes || 0);
      setIsLikeActive(false);
      setIsDislikeActive(false);
    } catch (error) {
      toast.error(error.message || "Failed to post comment.", {
        position: "top-right",
        autoClose: 2000, // 2 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle Like
  const handleLike = async () => {
    if (isProcessingLike) return; // Prevent multiple clicks during processing

    setIsProcessingLike(true);
    setLikeAnimation(true); // Trigger animation

    // Optimistically update the UI
    setLikes((prev) => prev + 1);
    setIsLikeActive(true);

    try {
      // Call API to handle like
      await blogLike(blog.id, "like");
    } catch (error) {
      console.error("Failed to update like:", error.message);
      // Revert to previous state in case of failure
      setLikes((prev) => prev - 1);
      setIsLikeActive(false);
    } finally {
      setIsProcessingLike(false);
      // Remove animation class after animation duration
      setTimeout(() => {
        setLikeAnimation(false);
      }, 750); // Match the animation duration in CSS
    }
  };

  // Handle Dislike
  const handleDislike = async () => {
    if (isProcessingDislike) return; // Prevent multiple clicks during processing

    setIsProcessingDislike(true);
    setDislikeAnimation(true); // Trigger animation

    // Optimistically update the UI
    setDislikes((prev) => prev + 1);
    setIsDislikeActive(true);

    try {
      // Call API to handle dislike
      await blogLike(blog.id, "dislike");
    } catch (error) {
      console.error("Failed to update dislike:", error.message);
      // Revert to previous state in case of failure
      setDislikes((prev) => prev - 1);
      setIsDislikeActive(false);
    } finally {
      setIsProcessingDislike(false);
      // Remove animation class after animation duration
      setTimeout(() => {
        setDislikeAnimation(false);
      }, 750); // Match the animation duration in CSS
    }
  };

  // **New Handler to Toggle Comment Section Visibility**
  const toggleComments = () => {
    setShowComments((prev) => !prev);
  };

  // Function to handle copying the blog URL to clipboard
  const handleCopyLink = () => {
    const blogUrl = window.location.href; // Get the current blog URL
    navigator.clipboard
      .writeText(blogUrl) // Copy the URL to clipboard
      .then(() => {
        toast.success("Link copied to clipboard!", {
          position: "top-right",
          autoClose: 2000, // 2 seconds
        });
      })
      .catch((error) => {
        toast.error("Failed to copy link.", {
          position: "top-right",
          autoClose: 2000, // 2 seconds
        });
        console.error("Error copying link: ", error);
      });
    setIsDropdownOpen(false);
  };

  // **New Function to Handle Sharing on X.com**
  const handleShareOnX = () => {
    const blogUrl = encodeURIComponent(window.location.href);
    const blogTitle = blog.title; // Blog title from the `blog` object

    const tweetText = `${blogTitle} 
    ${blogUrl}`;

    // Assuming X.com uses a similar intent URL as Twitter
    const xShareUrl = `https://x.com/intent/tweet?text=${tweetText}`;
    // Open a new window for sharing with custom size and configuration
    const windowFeatures =
      "width=600,height=500,resizable=yes,scrollbars=yes,toolbar=no,menubar=no";
    window.open(xShareUrl, "shareWindow", windowFeatures);
    setIsDropdownOpen(false); // Close the dropdown after sharing
  };

  const handleShareOnFacebook = () => {
    const blogUrl = encodeURIComponent(window.location.href); // Current blog URL
    const blogTitle = blog.title; // Blog title from the `blog` object

    // Construct the Facebook share URL
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${blogUrl}&title=${blogTitle}`;

    // Open a new window for sharing with custom size and configuration
    const windowFeatures =
      "width=600,height=500,resizable=yes,scrollbars=yes,toolbar=no,menubar=no";
    window.open(facebookShareUrl, "shareWindow", windowFeatures);
    setIsDropdownOpen(false); // Close the dropdown after sharing
  };

  const handleShareOnLinkedIn = () => {
    const blogUrl = encodeURIComponent(window.location.href); // Current blog URL
    const blogTitle = blog.title; // Blog title from the `blog` object

    // Construct the Facebook share URL
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${blogUrl}&title=${blogTitle}`;

    // Open a new window for sharing with custom size and configuration
    const windowFeatures =
      "width=600,height=500,resizable=yes,scrollbars=yes,toolbar=no,menubar=no";
    window.open(linkedinShareUrl, "shareWindow", windowFeatures);
    setIsDropdownOpen(false); // Close the dropdown after sharing
  };

  return (
    <>
      <div className="blogs-page">
        {/* If the blog is valid, apply SEO meta tags */}
        {blog && (
          <SEO
            title={`${blog.title} | Coltec Global`}
            description={blog.metaDescription}
            keywords={blog.keywords}
            ogTitle={blog.title}
            ogDescription={blog.metaDescription}
            ogImage={blog.image}
            ogUrl={`https://coltec-global.com/blogs/${blog.slug}`}
            canonicalUrl={`https://coltec-global.com/blogs/${blog.slug}`}
            ogSiteName="Coltec Global"
            ogType="website"
          />
        )}
        <Header />
        <div className="relative">
          <div className="mx-auto max-w-7xl px-6 pt-24 pb-12 lg:px-8 md:pt-28 md:pb-14">
            {showEbookBanner && (
              <div
                className="cursor-pointer md:hidden block absolute top-[58rem] z-[10] left-3 right-3 mb-8"
                onClick={handleEbookClick}
              >
                <img
                  src="https://content.qeeb.in/coltec/blogs/e-book-popup.webp"
                  alt="Download eBook"
                  className="slide-right rounded-md border"
                />
              </div>
            )}
            <div className="grid grid-cols-12 xl:gap-x-10 gap-6 min-h-screen">
              {blog ? (
                <>
                  <div className="xl:col-span-8 col-span-full relative">
                    <div className="mb-12 relative">
                      <Link to="/blogs">
                        <img
                          alt="Blogs Arrow"
                          src="https://content.qeeb.in/coltec/arrow-black.svg"
                          className="w-6 absolute top-[-26px] xl:top-0 xl:left-[-30px] xl:pt-2.5"
                        />
                      </Link>
                      <div className="flex space-x-2">
                        <h1 className="md:text-[30px] text-[22px] leading-8 font-semibold mb-2.5 max-w-2xl">
                          {blog.title}
                        </h1>
                      </div>
                      <div className="flex items-center">
                        <p className="font-medium text-sm mr-3">
                          <span className="text-gray-900">
                            {blog.author_name}
                          </span>
                        </p>
                        <p className="text-gray-500">
                          <span className="text-sm">{blog.date}</span>
                        </p>
                        <div className="md:block hidden">
                          <div className="flex justify-start items-center ml-3">
                            <div>
                              <img
                                alt="Category"
                                src="https://content.qeeb.in/coltec/category-icon.svg"
                                className="h-5"
                              />
                            </div>
                            <Link
                              to={blog.category_url}
                              className="bg-white border border-[#242424] ml-3 text-[#000] hover:text-[#242424] py-1 px-3 rounded-full text-sm"
                            >
                              {blog.category}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="md:hidden block">
                        <div className="flex justify-start items-center mt-3">
                          <div>
                            <img
                              alt="Category"
                              src="https://content.qeeb.in/coltec/category-icon.svg"
                              className="h-5"
                            />
                          </div>
                          <Link
                            to={blog.category_url}
                            className="bg-white border border-[#242424] ml-3 text-[#000] hover:text-[#242424] py-1 px-3 rounded-full text-sm"
                          >
                            {blog.category}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <img
                      alt={blog.alt}
                      src={blog.image}
                      className="rounded-lg w-full mb-6"
                    />
                    <div
                      className="prose mt-12"
                      dangerouslySetInnerHTML={{ __html: blog.content }}
                    ></div>
                  </div>

                  <div className="xl:col-span-4 col-span-full">
                    <div className="sticky top-[18rem]">
                      {/* NEW: E-Book banner at 800px scroll */}
                      {showEbookBanner && (
                        <div
                          className="cursor-pointer md:block hidden mb-8"
                          onClick={handleEbookClick}
                        >
                          <img
                            src="https://content.qeeb.in/coltec/blogs/e-book-popup.webp"
                            alt="Download eBook"
                            className="slide-right rounded-md border"
                          />
                        </div>
                      )}
                      {/* Latest Two Blogs */}
                      <h2 className="font-medium text-xl mb-3">Latest Blogs</h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:block">
                        {latestTwoBlogs.map((latestBlog) => (
                          <div
                            key={latestBlog.id}
                            className="flex space-x-4 mb-6"
                          >
                            <div className="shrink-0">
                              <img
                                alt={latestBlog.alt}
                                src={latestBlog.image}
                                className="w-[150px] rounded-md h-[90px]"
                              />
                            </div>
                            <div>
                              <Link to={`/blogs/${latestBlog.slug}`}>
                                <h3 className="text-sm font-medium hover:underline">
                                  {latestBlog.title}
                                </h3>
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                      <h2 className="font-medium text-xl mt-8 mb-3">
                        Blog Categories
                      </h2>
                      <div className="flex flex-wrap gap-3">
                        <Link to="/blogs/interactive-flat-panel">
                          <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                            Interactive Flat Panel
                          </div>
                        </Link>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Classroom Platform
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Collaboration
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Conferencing
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Tablet
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Room Scheduler
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Kiosks
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Video Wall
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          Active LED
                        </div>
                        <div className="bg-[#F2F2F2] rounded-full py-[4px] px-3 text-[#242424] text-sm">
                          All-in-One Touch Monitor
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-full">
                    {/* Likes and Dislikes Counts */}
                    <div className="flex space-x-4 items-center">
                      <button
                        onClick={handleLike}
                        disabled={isProcessingLike}
                        className={`flex flex-col items-center focus:outline-none ${
                          isLikeActive ? "cursor-pointer" : "cursor-pointer"
                        }`}
                        aria-pressed={isLikeActive}
                        aria-label="Like this blog"
                      >
                        <div className="flex space-x-1 items-center relative">
                          {isLikeActive ? (
                            <AiFillLike
                              className={`${
                                likeAnimation ? "like-button" : ""
                              } text-[22px] text-blue-500`}
                            />
                          ) : (
                            <AiOutlineLike className="text-[22px] text-gray-800" />
                          )}
                          <span className="text-gray-800 font-medium mt-0.5">
                            {likes}
                          </span>
                        </div>
                      </button>
                      <button
                        onClick={handleDislike}
                        disabled={isProcessingDislike}
                        className={`flex flex-col items-center focus:outline-none ${
                          isDislikeActive
                            ? "cursor-pointer hidden"
                            : "cursor-pointer hidden"
                        }`}
                        aria-pressed={isDislikeActive}
                        aria-label="Dislike this blog"
                      >
                        <div className="flex items-center space-x-1">
                          {isDislikeActive ? (
                            <AiFillDislike
                              className={`${
                                dislikeAnimation ? "dislike-button" : ""
                              } text-[22px] text-red-500`}
                            />
                          ) : (
                            <AiOutlineDislike className="text-[22px] text-gray-800" />
                          )}
                          <span className="text-gray-800 font-medium">
                            {dislikes}
                          </span>
                        </div>
                      </button>
                      {/* **New Comment Icon Button** */}
                      <button
                        onClick={toggleComments}
                        className={`flex flex-col items-center focus:outline-none cursor-pointer`}
                        aria-pressed={showComments}
                        aria-label="Toggle comments section"
                      >
                        <div className="flex items-center space-x-1">
                          <AiOutlineComment className="text-[22px] text-gray-500 hover:text-gray-900" />
                          <span className="text-gray-800 font-medium">
                            {comments.length}
                          </span>
                        </div>
                      </button>
                      <div className="relative">
                        {/* Share Button */}
                        <button
                          onClick={toggleDropdown}
                          className="flex items-center space-x-2 text-gray-500 hover:text-black"
                        >
                          <FiShare className="text-[20px]" />
                        </button>

                        {/* Dropdown Menu */}
                        {isDropdownOpen && (
                          <div className="absolute top-10 right-0 left-0 bg-white border shadow-lg rounded-md w-48">
                            <button
                              onClick={handleCopyLink} // Add the click handler here
                              className="flex items-center text-left w-full px-4 py-3 text-sm text-gray-500 hover:text-gray-900"
                            >
                              <IoIosLink className="text-[16px] mr-1" />
                              Copy link
                            </button>
                            <div className="border-t border-gray-200"></div>
                            <button
                              onClick={handleShareOnX} // Attach the new handler here
                              className="flex items-center text-left w-full px-4 py-3 text-sm text-gray-500 hover:text-gray-900"
                            >
                              <FaXTwitter className="text-[14px] mr-1" />
                              Share on X
                            </button>
                            <button
                              onClick={handleShareOnFacebook}
                              className="flex items-center text-left w-full px-4 py-3 text-sm text-gray-500 hover:text-gray-900"
                            >
                              <FaFacebook className="text-[16px] mr-1" />
                              Share on Facebook
                            </button>
                            <button
                              onClick={handleShareOnLinkedIn}
                              className="flex items-center text-left w-full px-4 py-3 text-sm text-gray-500 hover:text-gray-900"
                            >
                              <FaLinkedin className="text-[16px] mr-1" />
                              Share on LinkedIn
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Comment Section */}
                    {showComments && (
                      <div className="mt-8">
                        <h2 className="text-2xl font-medium mb-4">
                          Leave a Comment
                        </h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                          <div>
                            <label
                              htmlFor="username"
                              className="block text-sm font-normal text-gray-800"
                            >
                              Name
                            </label>
                            {user ? (
                              <div className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 bg-gray-100">
                                {user.name}
                              </div>
                            ) : (
                              <input
                                type="text"
                                id="username"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                placeholder="Your name"
                              />
                            )}
                          </div>
                          <div>
                            <label
                              htmlFor="comment"
                              className="block text-sm font-normal text-gray-800"
                            >
                              Comment
                            </label>
                            <textarea
                              id="comment"
                              name="comment"
                              rows="4"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              required
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                              placeholder="Your comment"
                            ></textarea>
                          </div>
                          <div>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className={`inline-flex mt-2 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                                isSubmitting
                                  ? "bg-gray-400"
                                  : "bg-blue-500 hover:bg-blue-600"
                              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                            >
                              {isSubmitting ? "Submitting..." : "Post Comment"}
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                    {/* Display Comments */}
                    <div className="mt-8">
                      <h2 className="text-2xl font-medium mb-6">Comments</h2>
                      {isLoadingComments ? (
                        <p>Loading comments...</p>
                      ) : commentsError ? (
                        <p className="text-red-600">{commentsError}</p>
                      ) : comments.length === 0 ? (
                        <p>No comments yet. Be the first to comment!</p>
                      ) : (
                        <ul className="space-y-3">
                          {comments.map((cmt) => (
                            <li
                              key={cmt.commentId}
                              className="bg-neutral-50/80 p-4 rounded-lg"
                            >
                              <div className="flex items-center">
                                {user && user.avatar ? (
                                  <img
                                    src={user.avatar}
                                    alt={`${cmt.username}'s avatar`}
                                    className="w-9 h-9 mr-3 rounded-full"
                                  />
                                ) : (
                                  <img
                                    src="https://content.qeeb.in/coltec/default-avatar.png"
                                    alt="Default Avatar"
                                    className="w-9 h-9 mr-3 rounded-full"
                                  />
                                )}
                                <div>
                                  <p className="text-base font-medium">
                                    {cmt.username}
                                  </p>
                                  <p className="text-gray-500 text-[11px]">
                                    {formatCommentDate(cmt.createdAt)}
                                  </p>
                                </div>
                              </div>

                              <p className="text-gray-800 text-sm mt-3">
                                {cmt.comment}
                              </p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <p>Blog not found</p>
              )}
            </div>
          </div>

          {/* 
            The popup is shown only if:
            1. CTA is visible (showSuggestion === true)
            2. There is either a previous blog or next blog
          */}
          {showSuggestion && (prevBlog || nextBlog) && (
            <div className="px-6 lg:px-8 max-w-7xl mx-auto pb-14">
              <ul
                className={`xl:flex space-y-4 xl:divide-x-2 xl:space-y-0 ${
                  prevBlog && nextBlog
                    ? "justify-between"
                    : prevBlog
                    ? "justify-start"
                    : nextBlog
                    ? "justify-end"
                    : ""
                }`}
              >
                {prevBlog && (
                  <Link to={`/blogs/${prevBlog.slug}`} className="group">
                    <li className="flex justify-start items-center">
                      <div className="mr-3">
                        <img
                          alt="blogs-arrow"
                          className="h-4"
                          src="https://content.qeeb.in/coltec/blogs-arrow-l.svg"
                        />
                      </div>
                      <div>
                        <p className="text-base font-light text-gray-700">
                          Previous
                        </p>
                        <p className="xl:w-[500px] text-base">
                          {prevBlog.title}
                        </p>
                      </div>
                    </li>
                  </Link>
                )}
                {nextBlog && (
                  <Link to={`/blogs/${nextBlog.slug}`} className="group pl-8">
                    <li className="flex justify-end items-center">
                      <div className="text-right">
                        <p className="text-base font-light text-gray-700">
                          Next
                        </p>
                        <p className="xl:w-[500px] text-base">
                          {nextBlog.title}
                        </p>
                      </div>
                      <div className="ml-3">
                        <img
                          alt="blogs-arrow"
                          className="h-4"
                          src="https://content.qeeb.in/coltec/blogs-arrow-r.svg"
                        />
                      </div>
                    </li>
                  </Link>
                )}
              </ul>
            </div>
          )}
        </div>
        <div>
          <CTA />
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};

export default BlogDetails;
