import axios from "axios";

// Get base URL from .env file. For example, REACT_APP_API_URL might be "https://devapi.qeeb.in"
const baseUrl = process.env.REACT_APP_API_URL;

export const createDiscountCoupon = async (discount, description, expiryDate) => {
  try {
    // Prepare the payload according to your API requirements
    const payload = {
      percenrage: discount,      // Note: The key "percenrage" is used as per your API spec.
      description: description,
      experyDate: expiryDate      // The expiry date as a string (e.g., "2025-01-21")
    };

    // Make a POST request to the API endpoint with the payload and headers
    const response = await axios.post(
      `${baseUrl}/api/ColtecAdmin/CreateDiscountcoupon`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating coupon:", error);
    throw error;
  }
};
