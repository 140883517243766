  import axios from "axios";
  // Get base URL from .env file
  const baseUrl = process.env.REACT_APP_API_URL;
  export const getUserQuotations = async (email) => {
    const url = `${baseUrl}/api/Coltec/GetUserQuotations?Email=${encodeURIComponent(
          email
        )}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      // Optionally, you can inspect error.response for more details
      throw error;
    }
  };
    