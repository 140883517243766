import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import OurClients from "../components/OurClients";
import ScrollToTopButton from "../components/ScrollTop";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import SEO from "../components/SEO";
import WhatsAppMsg from "../components/WhatsApp";
const stats = [
  { label: "Enabled Collaboration Spaces", value: "1.4 Billion+" },
  { label: "Serving Countries", value: "80+" },
  { label: "Global Team", value: "1200+" },
  { label: "Customer Happiness", value: "98.5%" },
];

// Function to parse the numeric value
const parseValue = (value) => {
  const numericValue = value.match(/[\d.]+/);
  return numericValue ? parseFloat(numericValue[0]) : 0;
};

// Function to parse the suffix
const parseSuffix = (value) => {
  return value.replace(/[\d.]+/g, "").trim();
};
const AboutUs = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger the effect only once
    threshold: 0.6, // Trigger when 60% of the section is visible
  });
  return (
    <>
      <SEO
        title="About Coltec Global: Innovative Interactive Flat Panels & Beyond"
        description="Discover Coltec’s commitment to innovation—designing interactive flat panels that unite people, spark creativity, and transform modern learning and work."
        keywords="Our Vision, Our Journey, Innovation Lab, User Friendly Solutions, Industry Transformation"
        ogTitle="About Us - Innovation Drives Excellence | Coltec Global"
        ogDescription="Discover Coltec’s commitment to innovation—designing interactive flat panels that unite people, spark creativity, and transform modern learning and work."
        ogImage="https://content.qeeb.in/coltec/coltec-logo-black.svg"
        ogUrl="https://coltec-global.com/about-us"
        canonicalUrl="https://coltec-global.com/about-us"
        ogImageAlt="About Coltec: Innovative Interactive Flat Panels & Beyond"
        ogSiteName="Coltec Global"
        ogType="website"
      />
      <div className="bg-[#E9E9E9] about-us-page">
        <Header />
        <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 lg:py-14 lg:px-8 pb-10 pt-28">
            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
              <div className="w-full max-w-xl xl:max-w-2xl -mt-6">
                <h1 className="text-[24px] md:text-4xl text-gray-900 font-semibold md:mb-10 mb-3">
                  Our Journey: Collaboration with Interactive Flat
                  Panels
                </h1>

                <p className="relative text-sm md:text-base leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  At COLTEC, innovation drives excellence. Our Innovation Lab is
                  dedicated to developing cutting-edge products that redefine
                  functionality and performance, transforming classrooms
                  worldwide. We focus on bringing the latest technology to
                  market, enhancing learning experiences with high-quality,
                  user-friendly solutions.
                </p>
                <p className="relative mt-4 text-sm md:text-base leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Our team is committed to continuous improvement, designing
                  products that exceed the expectations of modern educators.
                  Practical, reliable, and future-proof, COLTEC’s offerings are
                  crafted with educators and students in mind, shaping the
                  future of education through innovative design.
                </p>
              </div>
              <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-10 lg:pl-0">
                <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                  <div className="relative">
                    <img
                      alt="COLTEC corporate headquarters showcasing modern architectural design and brand identity."
                      src="https://content.qeeb.in/coltec/aboutus/image-1.webp"
                      className="aspect-[2/2] md:block hidden w-full rounded-lg bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="mr-auto w-40 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                  <div className="relative">
                    <img
                      alt="COLTEC corporate headquarters showcasing modern architectural design and brand identity."
                      src="https://content.qeeb.in/coltec/aboutus/image-2.webp"
                      className="aspect-[2/3] w-full rounded-lg bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <img
                      alt="COLTEC corporate headquarters showcasing modern architectural design and brand identity."
                      src="https://content.qeeb.in/coltec/aboutus/image-5.webp"
                      className="aspect-[2/3] w-full rounded-lg bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
                <div className="w-44 flex-none space-y-8 pt-1 sm:pt-0">
                  <div className="relative">
                    <img
                      alt="COLTEC corporate headquarters showcasing modern architectural design and brand identity."
                      src="https://content.qeeb.in/coltec/aboutus/image-4.webp"
                      className="aspect-[2/2] w-full rounded-lg bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                  </div>
                  <div className="relative">
                    <img
                      alt="COLTEC corporate headquarters showcasing modern architectural design and brand identity."
                      src="https://content.qeeb.in/coltec/aboutus/image-3.webp"
                      className="aspect-[2/3] w-full rounded-lg bg-gray-900/5 object-cover shadow-lg"
                    />
                    <div className="pointer-events-none absolute inset-0 rounded-lg ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden">
        {/* Content section */}
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-medium tracking-tight text-gray-900">
              Our Vision
            </h2>
            <div className="mt-6 flex flex-col gap-x-8 md:gap-y-20 gap-y-10 lg:flex-row">
              <div className="lg:w-full lg:max-w-xl lg:flex-auto">
                <p className="text-sm">
                  Our vision is to be at the heart of every space where people
                  collaborate, becoming synonymous with productivity and
                  connection. We aim to transform the world through innovative
                  products that bring people together and drive success.
                </p>
                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  <p className="text-sm">
                    At COLTEC, the customer comes first. Providing
                    state-of-the-art solutions is our primary goal, and our
                    dedication to customer satisfaction is the foundation of
                    everything we do.
                  </p>
                  <p className="mt-10 text-sm">
                    With deep expertise in the display market, our team is
                    uniquely positioned to understand and meet our customers’
                    needs. We are a group of highly focused individuals
                    committed to creating innovative solutions that enhance
                    collaboration and boost productivity, both for individuals
                    and teams, driving organizational success.
                  </p>
                </div>
              </div>
              <div ref={ref} className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 md:space-y-6 space-y-4 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse">
                      <dt className="text-sm leading-7 text-gray-600">
                        {stat.label}
                      </dt>
                      <dd className="text-3xl font-medium leading-7 tracking-tight text-gray-900">
                        <CountUp
                          start={inView ? 0 : null} // Start the animation when in view
                          end={parseValue(stat.value)} // Numeric part for CountUp
                          duration={2} // Animation duration in seconds
                          separator=","
                          suffix={parseSuffix(stat.value)}
                          delay={0}
                        />
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-1/2 top-4 h-[500px] w-full -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:top-[17rem] xl:ml-0">
          <svg
            viewBox="0 0 1026 1026"
            fill="none"
            aria-hidden="true"
            className="absolute inset-0 h-full w-full animate-spin-slow"
          >
            <path
              d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
              stroke="#D4D4D4"
              strokeOpacity="0.9"
            ></path>
            <path
              d="M513 1025C230.23 1025 1 795.77 1 513"
              stroke="url(#:S1:-gradient-1)"
              strokeLinecap="round"
            ></path>
            <defs>
              <linearGradient
                id=":S1:-gradient-1"
                x1="1"
                y1="513"
                x2="1"
                y2="1025"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#06b6d4"></stop>
                <stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
          <svg
            viewBox="0 0 1026 1026"
            fill="none"
            aria-hidden="true"
            className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
          >
            <path
              d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
              stroke="#D4D4D4"
              strokeOpacity="0.9"
            ></path>
            <path
              d="M913 513c0 220.914-179.086 400-400 400"
              stroke="url(#:S1:-gradient-2)"
              strokeLinecap="round"
            ></path>
            <defs>
              <linearGradient
                id=":S1:-gradient-2"
                x1="913"
                y1="513"
                x2="913"
                y2="913"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#06b6d4"></stop>
                <stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="bg-gray-50">
        <div className="px-10 lg:px-8 lg:py-14 py-10 max-w-5xl mx-auto">
          <h3 className="text-3xl font-medium md:mb-0 mb-8">Our Journey</h3>
          <div className="flex flex-col md:grid grid-cols-9 mx-auto">
            <div className="flex flex-row-reverse md:contents">
              <div className="w-full col-start-1 col-end-5 mb-12 mt-12 ml-auto">
                <p className="text-sm max-w-xs leading-6 pt-4">
                  COLTEC Global established in 1995 in 155 - Hillcrest Avenue,
                  Mississauga, Canada
                </p>
              </div>

              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none dot-point"></div>
                </div>

                <div className="w-16 h-16 absolute top-1/2 ml-[-20px] -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  1995
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2000
                </div>
              </div>

              <div className="w-full col-start-6 col-end-10 mb-4 mt-12 mr-auto rounded-xl">
                <p className="text-sm leading-6">
                  A team of 250 majorly focusing on electronics & Communication
                  equipment sales & service.
                </p>
              </div>
            </div>

            <div className="flex flex-row-reverse md:contents">
              <div className="w-full col-start-1 col-end-5 mb-4 mt-12 ml-auto">
                <p className="text-sm leading-6">
                  Launched Touch Interface color Display system for specialized
                  Applications in 2005 at New York Trade show growing its team
                  to 750 serving Europe, USA Region
                </p>
              </div>

              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2005
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2007
                </div>
              </div>

              <div className="w-full col-start-6 col-end-10 mb-4 mt-12 mr-auto">
                <p className="text-sm leading-6">
                  Launched Smart Boards for Education Teaching & Learning in
                  2007. Introduced Smart Board Technology in APAC Region.
                </p>
              </div>
            </div>
            <div className="flex flex-row-reverse md:contents">
              <div className="w-full col-start-1 col-end-5 mb-4 mt-12 ml-auto">
                <p className="text-sm leading-6">
                  Launched Smart LCD Touch Interactive Display in 2010 serving
                  APAC Region.
                </p>
              </div>

              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2010
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2015
                </div>
              </div>

              <div className="w-full col-start-6 col-end-10 mb-4 mt-12 mr-auto">
                <p className="text-sm leading-6">
                  Formed Dedicated Regional Teams for Sales & Service in India
                  since 2015 serving Education & Corporate Markets.
                </p>
              </div>
            </div>
            <div className="flex flex-row-reverse md:contents">
              <div className="w-full col-start-1 col-end-5 mb-4 mt-12 ml-auto">
                <p className="text-sm leading-6">
                  Extended Division into Classroom Technology | Teaching &
                  Learning
                </p>
              </div>

              <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2017
                </div>
              </div>
            </div>

            <div className="flex md:contents">
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div className="h-full w-6 flex items-center justify-center">
                  <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                </div>

                <div className="w-16 h-16 absolute ml-[-20px] top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-gray-100 shadow flex items-center justify-center text-blue-500 font-semibold">
                  2019
                </div>
              </div>

              <div className="w-full col-start-6 col-end-10 mb-8 mt-12 mr-auto">
                <p className="text-sm leading-6">
                  Launched Professional Integrated Solutions for Campuses
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <OurClients />
      </div>
      <CTA />
      <Footer />
      <ScrollToTopButton />
      <WhatsAppMsg />
    </>
  );
};

export default AboutUs;
