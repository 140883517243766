import React, { useState } from "react";
import { createDiscountCoupon } from "../utils/createDiscountCoupon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateDiscountCoupon = ({ refreshCoupons }) => {
  const [discount, setDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState(""); // New state for expiry date

  const handleCreateCoupon = async (e) => {
    e.preventDefault();
    try {
            // Ensure the expiry date is formatted as "YYYY-MM-DD"
      // The input returns this format by default, but this conversion guarantees it.
      const formattedExpiryDate = expiryDate
        ? new Date(expiryDate).toISOString().split("T")[0]
        : "";

      const result = await createDiscountCoupon(
        discount,
        description,
        formattedExpiryDate
      );
      if (result?.status === 1) {
        // Show success toast
        toast.success(result.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Call the parent callback to refresh the coupons list
        if (refreshCoupons) {
          refreshCoupons();
        }
        // Clear the form fields
        setDiscount("");
        setDescription("");
        setExpiryDate("");
      } else {
        // Show error toast if status is not 1
        toast.error(result?.message || "Failed to create coupon", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while creating coupon", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <h2 className="text-lg font-semibold mb-4">Create Discount Coupon</h2>
      <form onSubmit={handleCreateCoupon} className="space-y-6">
        <div>
          <label className="block text-sm font-normal mb-2">
            Discount Percentage
          </label>
          <input
            type="number"
            className="border text-[15px] flex justify-center items-center px-3 border-gray-200  py-2 rounded-full w-full"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            placeholder="e.g. 15"
            required
            step="0.01"
          />
        </div>
        <div>
          <label className="block text-sm font-normal mb-2">Description</label>
          <input
            type="text"
            className="border text-[15px] flex justify-center items-center px-3 border-gray-200  py-2 rounded-full w-full"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="e.g. Coupon Code COLTEC"
            required
          />
        </div>
        <div className="w-full">
          <label className="block text-sm font-normal mb-2">Expiry Date</label>
          <input
            type="date"
            className="border text-[15px] px-3 border-gray-200 py-2 rounded-full w-full"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 rounded-full text-white px-6 py-2"
        >
          Create Coupon
        </button>
      </form>
      {/* Toast Container for displaying notifications */}
      <ToastContainer />
    </>
  );
};

export default CreateDiscountCoupon;
